import React from 'react';

import ErrorPage from '@components/shared/ErrorPage';

const Page404 = () => (
  <section className="page-404">
    <div className="container">
      <div className="row">
        <div className="col-xxs-12">
          <ErrorPage
            src="/images/people-with-home.svg"
            title="Something went wrong"
            text="But since when has that ever stopped you?"
          />
        </div>
      </div>
    </div>
  </section>
);

export default Page404;
