import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Title from '@components/shared/Title';
import Text from '@components/shared/Text';
import Button from '@components/shared/Button';
import routes from '@constants/routes';

const ErrorPage = ({ src, title, text, button, className }) => (
  <div className={classNames('error-page', className)}>
    <div className="error-page__content">
      <div className="error-page__media">
        <img src={src} alt={title} className="error-page__img" />
      </div>
      {title && (
        <Title size="md" className="error-page__title">
          {title}
        </Title>
      )}
      {text && (
        <Text size="lg" className="error-page__text">
          {text}
        </Text>
      )}
      {button && (
        <Button
          href={button.href}
          onClick={button.onClick}
          color="yellow"
          className="error-page__btn"
        >
          {button.text}
        </Button>
      )}
    </div>
  </div>
);

ErrorPage.defaultProps = {
  button: {
    text: 'Back to home',
    href: routes.home
  }
};

ErrorPage.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.object,
  className: PropTypes.string
};

export default ErrorPage;
